
import { computed, defineComponent, onMounted, ref } from "vue";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import AuthStore from "@/store/auth-store";
import { useRoute, useRouter } from "vue-router";
import { formatDate_h, notify, useFormatDate } from "@/services/helpers";
import { IEmergencyContact } from "@/types/EmergencyContact";
import draggable from "vuedraggable";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";

export default defineComponent({
  name: "EmployeeEmergencyContact",
  components: { draggable, ConfirmationModal },
  setup() {
    const workForceService = new WorkForceService();
    const emergencyContacts = ref<IEmergencyContact[]>([]);
    const confirmDialogue = ref();
    const rolesEnum = {
      admin: "Admin",
      manager: "Manager",
      orgAdmin: "Org Admin",
    };

    const message = ref("");
    const authId = ref(AuthStore.getTokenData().EmployeeId);
    const authRole = ref(AuthStore.getTokenData().Roles);
    const isAdmin = ref(
      AuthStore.getTokenData().Roles == rolesEnum.admin ||
        AuthStore.getTokenData().Roles == rolesEnum.orgAdmin
    );
    const canEdit = ref(false);

    const employeeId = ref(0);

    const route = useRoute();

    const emergencyContactId = ref(0);
    const deleteEmergencyContact = async (id: number) => {
      emergencyContactId.value = id;
      const modal = await confirmDialogue.value.show({
        title: "Delete Contact?",
        message:
          "Are you sure you want to delete this Emergency Contact? It cannot be undone.",
        okButton: "Delete",
        cancelButton: "Cancel",
        theme: "danger",
        type: "confirmation",
      });
      if (modal) {
        workForceService
          .delete(`/emergencycontact/${emergencyContactId.value}`)
          .then((response: any) => {
            if (!response.isError) {
              notify("Contact deleted successfully.", "Success", "success");
            } else {
              notify(response.errors, "Error", "danger");
            }
            initialize();
            emergencyContactId.value = 0;
          });
      } else {
        emergencyContactId.value = 0;
        return false;
      }
    };

    // Emergency Contact
    const loadingContact = ref(false);

    const loading = ref(false);

    const canInviteOrEdit = (loggedInRole: string, inviteeRole: string) => {
      if (
        loggedInRole == rolesEnum.manager &&
        (inviteeRole == rolesEnum.orgAdmin || inviteeRole == rolesEnum.admin)
      ) {
        return false;
      }
      return true;
    };

    const checkMove = async () => {
      const list = emergencyContacts.value.map((item, index) => {
        return {
          id: item.id,
          priority: index + 1,
        };
      });

      loadingContact.value = true;
      await workForceService
        .post(
          `/EmergencyContact/updatePriority?EmployeeId=${employeeId.value}`,
          list
        )
        .then(() => {
          loadingContact.value = false;
        });
    };

    const initialize = async () => {
      loading.value = true;

      // Load Employee
      employeeId.value = Number(route.params.id);

      await workForceService
        .get<IEmergencyContact[]>(
          `/emergencycontact?EmployeeId=${employeeId.value}`,
          false
        )
        .then((response: any) => {
          emergencyContacts.value = response.content.items;
        });

      loading.value = false;
    };

    onMounted(async () => {
      canEdit.value = route.params.id == authId.value;

      await initialize();
    });

    const enabled = ref(true);

    const dragOptions = computed(() => {
      return {
        animation: 400,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    });

    return {
      workForceService,
      loadingContact,
      loading,
      canInviteOrEdit,
      initialize,
      isAdmin,
      canEdit,
      message,
      checkMove,
      confirmDialogue,
      deleteEmergencyContact,
      emergencyContacts,
      enabled,
      dragOptions,
    };
  },
});
